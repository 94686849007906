import React from 'react';
import { ReviewContainer } from './styles';

export const ProductReviewWidget = ({ product, parentVariant }) => (
  <ReviewContainer>
    <div className='interior'>
      <div
        className="yotpo yotpo-main-widget"
        data-product-id={parentVariant}
        data-price={product.priceRange.maxVariantPrice.amount}
        data-currency={product.priceRange.maxVariantPrice.currency}
        data-name={product.title}
        data-url={`https://www.coppercompression.com/products/${product.handle}`}
        data-image-url={product.images[0].localFile.childImageSharp.fluid.src}>
      </div>
    </div>
  </ReviewContainer>
)

export const ProductStarsWidget = ({ handle, parentVariant }) => (
  <div
    className="yotpo bottomLine"
    data-product-id={parentVariant}
    data-url={`https://www.coppercompression.com/products/${handle}`}>
  </div>
)

export const useYotpo = (showYotpo) => {
  return () => { 
    if (showYotpo) {
      try {
        // try to load widget
        window.yotpo.refreshWidgets()
      } catch (error) {
        setTimeout(() => {
          // caught an error - wait .4 seconds
          try {
            // try to load widget
            window.yotpo.refreshWidgets()
          } catch (error) {
            // widget didn't load after waiting
            // send reload as last ditch effort
            // disable reload as this is causing infinite loop
            // we will just let some pages load without reviews
            // window.location.reload()
          } 
        }, 400);
      }
    }
  }
}