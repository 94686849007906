import styled from '@emotion/styled';

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

export const ReviewContainer = styled.div`
  margin: 0 auto;
  max-width: 1320px;
  padding: 0 38px;

  @media (max-width: ${breakpoints.s}px){
    padding: 0;
  }

  .interior {
    max-width: 820px;
    margin: auto;

    .yotpo-label-container {
      display: none;
    }
  }
`
