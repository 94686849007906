import React from 'react'
import { Link } from 'gatsby';
import styled from '@emotion/styled'
import { breakpoints } from '../utils/styles'


const ReviewSticker = ({ last, icon, uses, topline, img, title, to, credit, caption, bg, reverse, sticker }) => {

  const StickerStyle = styled(Link)`

    max-width: 1420px;

    display: flex;
    flex-direction: ${props => props.reverse ? 'column-reverse' : 'column'};
    background-color: ${bg};
    align-items: center;
    text-decoration: none;

    margin-bottom: 30px;

    @media (min-width: ${breakpoints.l}px){
      margin: 30px auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    img {
      width: 100%;
      height: 100%;
      max-height: 500px;
      object-fit: cover;
      object-position: center;
      display: block;
    }

    p {
      font-family: 'HKNova-Medium';
      font-weight: normal;
      font-size: 14px;
      max-width: 520px;
      margin: 0 auto;
      padding: 20px;
    }

    h2 {
      font-family: 'HKNova-ExtraBold';
      letter-spacing: 0.10rem;
      font-weight: normal;
      font-size: 20px;
      margin: auto;
      margin-top: 0;
      margin-bottom: 12px;
      max-width: 420px;

      @media (min-width: ${breakpoints.l}px){
        font-size: 24px;
      }

    }

    h3 {
      letter-spacing: 0.350rem;
      font-family: 'gilroyExtraBold';
      text-transform: uppercase;
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 40px;

    }

    .caption-contain {
      text-align: center;
      height: 100%;
      width: 100%;
      color: white;
      padding: 30px 0;
      background-size: auto 100%;
      background-image: url('${sticker}');
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      background-position: ${props => props.reverse ? 'left' : 'right'};

      @media (min-width: ${breakpoints.l}px){
        background-position: center;
      }

    }
  `


  const ButtonLink = styled(Link)`
    background-color: rgba(255,255,255,0.9);
    border-radius: 10px;
    padding: 20px 50px;
    display: block;
    color: ${bg};
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    &:hover {
      background-color: rgba(255,255,255,1);
    }
  `



  const cta = 'Shop Now';

  return (
    <>
      <StickerStyle reverse={reverse} to={to}>
        { reverse && <div className='caption-contain'><h2>{title}</h2><p>{ caption }</p><h3>{credit}</h3>              <ButtonLink to={to}>{cta}</ButtonLink></div> }
        <div>
          <img src={ img } alt="Drew Brees - Strength To Heal" />
        </div>
        { !reverse &&
            <div className='caption-contain'>
              <h2>{title}</h2>
              <p>{caption}</p>
              <h3>{credit}</h3>
              <ButtonLink to={to}>{cta}</ButtonLink>
            </div>
        }
      </StickerStyle>
    </>
  )

}

export default ReviewSticker
