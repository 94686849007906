import React from 'react'
import SEO from '../../components/seo'
import SaleDetail from '../../components/DadSaleDetail'
import styled from '@emotion/styled'
import ReviewSticker from '../../components/reviewSticker'
import { Link } from 'gatsby'
import { Container } from '../../utils/styles'

import { breakpoints } from '../../utils/styles'
import { skyBlue, copper, oceanBlue } from '../../constants/theme';

import hero from '../../images/sales/fathers-day.jpg'
import dadSticker1 from '../../images/welcome/fd-1.jpg'
import dadSticker2 from '../../images/welcome/fd-2.jpg'
import dadSticker3 from '../../images/welcome/fd-3.jpg'
import dadSticker4 from '../../images/welcome/fd-4.jpg'
import dadSticker5 from '../../images/welcome/fd-5.jpg'

const DesktopImg = styled.img`
  width: 100%;
`

export const SalesBlockStyle = styled.div`
  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 40px;
  max-width: 1320px;
  margin: auto;
  text-align: center;

  p {
    font-size: 20px;
    font-family: 'HKNova-Medium';
    letter-spacing: 0.1rem;
    line-height: 1.4;
    max-width: 740px;
    margin: auto;
    margin-bottom: 30px;
    b {
      font-family: 'HKNova-ExtraBold';
    }
    a {
      color: ${oceanBlue};
      text-decoration: none;
    }
  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 30px;
    @media (max-width: ${breakpoints.s}px){
        font-size: 30px;
    }
  }

  h2 {
    font-family: 'HKNova-ExtraBold';
    letter-spacing: 0.10rem;
    font-weight: normal;
    font-size: 18px;
    color: ${skyBlue};
    margin-bottom: 5px;
  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 21px;
    color: ${copper};
    margin-left: 7px;
  }

  h4 {
    font-size: 15px;
    font-family: 'HKNova-Medium';
    letter-spacing: 0.1rem;
    line-height: 1.4;
    max-width: 600px;
    margin: auto;
    margin-bottom: 50px;
    font-weight: normal;
    a {
      color: ${oceanBlue};
      text-decoration: none;
    }


  }




  img {
    border-radius: 100%;
  }

`;


  const ButtonLink = styled(Link)`
    background-color: ${oceanBlue};
    border-radius: 10px;
    padding: 16px 48px;
    display: inline-block;
    color: white;
    margin: auto;
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    text-decoration: none;
    &:hover {
      background-color: ${skyBlue};
    }
  `



const ImageBlock = (props) => (
  <>
    <DesktopImg alt={props.title} src={props.img} />
  </>
)

const SalesBlock = () => (
  <SalesBlockStyle>
    <h3>The Ultimate</h3>
    <h1>Dad Approved Gift Guide</h1>
    <p>This week only, shop our Dad's Approved gifts at <b>30% off</b> with code DAD. Order by June 13th to get them in time for Father's Day.</p>
    <h4>Father's Day is just around the corner and shopping for dads can be quite the challenge! Whether the dad in your life coaches the team or works the grill at the family BBQ, we have the perfect gift for him.</h4>
    <ButtonLink to='/pages/fathers-day?discount=DAD'>APPLY CODE DAD</ButtonLink>
  </SalesBlockStyle>
)

const MomPage = () => {
  return (
    <Container>
      <SEO title="Father's Day Gift Guide" description="ather's Day is just around the corner and shopping for dads can be quite the challenge! Whether the dad in your life coaches the team or works the grill at the family BBQ, we have the perfect gift for him." keywords={ [`copper`, `compression`, `pain relief`, `strength to heal`] } />
      
      <ImageBlock img={hero} title="Mothers Day" />


      <SalesBlock />



      <ReviewSticker
        showName
        reverse
        to={'/products/copper-compression-wrist-brace-guaranteed-highest-copper-content-support-for-wrists-carpal-tunnel-arthritis-tendonitis-night-and-day-wrist-splint-for-men-and-women-fit-for-right-and-left-hand?discount=DAD'}
        img={ dadSticker1 }
        bg={ oceanBlue }
        title='The Grill Master'
        caption="“I have carpal tunnel, neuropathy, arthritis, and tendonitis from shoulder to wrist. Started having problems at work holding spatulas or flipping food on the grill. Wore this brace 1 day and saw a tremendous improvement in my comfort and range of motion. No pain all day.”"
        credit="Wrist Brace, — Raymond S"
      />

      <ReviewSticker
        showName
        img={ dadSticker2 }
        to={'/products/knee-sleeve-1?discount=DAD'}
        bg={ skyBlue }
        title='The Coach'
        caption="“This thing works! I wear them when I coach, and sometimes to work (factory job) and my knees feel great. My 11 y/o son noticed a difference in me during soccer practice. I made sure to let him know that I will be even harder to beat now (I'm a defender, he's a midfielder) I cannot explain how/why this knee sleeve works, but it has been absolutely the best thing I've purchased for my achy knees.”"
        credit="Recovery Knee Sleeve, — John M"
      />

      <ReviewSticker
        showName
        reverse
        to={'/products/copper-compression-elbow-sleeve?discount=DAD'}
        img={ dadSticker3 }
        bg={ oceanBlue }
        title='The Golfer'
        caption="“I'm 60 years old and play more golf than I deserve. I recently felt elbow pain ( yes, I take lots of strokes). I purchased the device and not only use it when I golf, I use it for exercise and lawn work. I am buying a second one to have as a back up in my golf bag. It works”"
        credit="Recovery Elbow Sleeve, — Ken B"
      />

      <ReviewSticker
        showName
        to={'/products/copper-compression-lower-back-lumbar-support-recovery-brace-for-pain?discount=DAD'}
        img={ dadSticker4 }
        bg={ skyBlue }
        title='Travel Junky'
        caption="“Husband wore it every day in Europe, traveling Belgium, Germany, Austria and Italy. He would not have been without it, and with all the backpacking, walking, etc., he was virtually pain-free the entire trip. Awesome!”"
        credit="Recovery Back Brace, — Tony K"
      />

      <ReviewSticker
        showName
        reverse
        to={'/products/copper-compression-recovery-foot-sleeves-plantar-fasciitis-support-socks?discount=DAD'}
        img={ dadSticker5 }
        bg={ oceanBlue }
        title='The Working Dad'
        caption="“I bought these for my dad's foot pain. He works in a factory on his feet all day and has plantar fasciitis. The first day he wore them at work he came home and gave me a hug he was so happy they helped his feet.”"
        credit="Recovery Foot Sleeves, — Amanda"
      />

      <SaleDetail tag='SHOP GIFTS' title='For The Fathers In Your Life'/>

    </Container>
  )
}

export default MomPage
